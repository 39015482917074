import { IUInputFieldProps } from "@/app/ui-kit/interfaces/ui-interfaces";
const InputField = (props: IUInputFieldProps) => {
  return (
    <div className={`flex flex-col gap-sp-10 w-full  `}>
      <label
        htmlFor={props?.id}
        className={` text-s-16 text-MdBlue leading-normal px-sp-10  ${
          props?.labelClassName ?? ""
        } `}
      >
        {props?.label}
      </label>
      <input
        placeholder={props?.placeholder ?? ""}
        type={props.type ?? "text"}
        className={` text-s-12 text-MdGrayLight focus:border-MdBlue focus:outline-none border-[1px] border-MdInputBorder px-sp-15  py-sp-10 ${
          props?.inputClassName ?? ""
        }`}
        name={props.name ?? ""}
        required={props.required}
        onChange={(e) => {
          if (e && props?.setValue) {
            props?.setValue(e?.target?.value);
          }
        }}
        id={`${props?.id}`}
        value={props?.value}
      />
    </div>
  );
};

export default InputField;
