import React from "react";
import { IPipelineItems } from "../../interfaces/ui-interfaces";
import ArrowNarrowRight from "../../elements/svgs/arrowNarrowRight";
import { ModalController } from "../../containers/model-wrapper";
import PipelineModal from "./pipelineModal";
import ImageNextHelper from "../../elements/image-next-helper";

export default function PipelineItems(props: IPipelineItems) {
  const { name, id, image } = props;
  return (
    <div>
      <div className=" relative h-[180px] flex items-end transition-all ease-in-out duration-400 group ">
        <ImageNextHelper
          src={image?.imageUrl}
          alt={name}
          fill
          objectClass="object-cover object-center "
          className="z-0 "
        />

        <div
          className={`flex flex-col gap-y-sp-5 relative z-20 w-full p-sp-10 rounded-b-[15px] items-center transition-all ease-in-out duration-400 
            group-hover:bg-MdWhite bg-transparent`}
        >
          <span>{name}</span>

          <div
            className={` transition-all ease-in-out duration-400 group-hover:opacity-100 group-hover:block hidden opacity-0 `}
          >
            <div
              onClick={() => {
                if (props.isPipeLine) {
                  ModalController.open(<PipelineModal {...props} />);
                } else {
                  window.location.href = `/products/${props.id}/${props.name}`;
                }
              }}
              className={`flex flex-row gap-x-sp-10 items-center hover:cursor-pointer   hover:-translate-y-[1px] hover:opacity-60 }`}
            >
              <span
                className={`text-s-10 text-medium text-MdBlue leading-normal`}
              >
                {"More info"}
              </span>
              <ArrowNarrowRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
