import React from "react";
import { ISmallArticleProps } from "../../interfaces/ui-interfaces";
import classes from "./styles.module.scss";
export default function SmallArticle(props: ISmallArticleProps) {
  const { headerTxt, articleTxt, greenBg } = props;
  return (
    <div className="flex flex-row mt-10">
      <div className="flex items-end">
        <div className="w-[1px] h-[85px] bg-MdBlue mx-sp-10" />
      </div>

      <div
        className={`flex flex-col gap-y-sp-30 ${
          greenBg ? classes.greenBg : ""
        }`}
      >
        <h2 className="font-Poppins text-s-50 text-MdBlue leading-[4rem]">
          {headerTxt}
        </h2>

        <article className="font-Poppins text-s-18">{articleTxt}</article>
      </div>
    </div>
  );
}
