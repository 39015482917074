import { IUInputFieldProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import classes from "./style.module.scss";
const TextAreaField = (props: IUInputFieldProps) => {
  const { labelClassName, label, inputClassName, placeholder, id, onChange } =
    props;
  return (
    <div
      className={`flex flex-col gap-1 w-full relative ${classes.inputField} `}
    >
      <label
        htmlFor={`${id}`}
        className={`text-s-16 text-MdBlue leading-normal px-sp-10 ${
          labelClassName ?? ""
        } `}
      >
        {label}
      </label>
      <textarea
        placeholder={placeholder ?? ""}
        className={`bg-transparent text-s-12     h-[7rem] px-3 py-[0.3125rem]  outline-none focus:outline-none  text-MdGrayLight focus:border-MdBlue !border !border-MdInputBorder ${
          inputClassName ?? ""
        }`}
        name={props.name}
        required={props.required}
        id={`${id}`}
        value={props?.value}
        onChange={(e) => {
          if (e && props?.setValue) props?.setValue(e?.target?.value);
        }}
      />
    </div>
  );
};

export default TextAreaField;
