import React from "react";
import { HeroSectionModel } from "../ui-models/HeroSectionModel";
import dynamic from "next/dynamic";
import { MainNavbarModel } from "../ui-models/ManNavbarModel";
import { InnerHeroSectionModel } from "../ui-models/InnerHeroSectionModel";
import { WhoWeAreSectionModel } from "../ui-models/WhoWeAreSectionModel";
import { OurServicesSectionModel } from "../ui-models/OurServicesSectionModel";
import { OurSuppliersSectionModel } from "../ui-models/OurSupplierSectionModel";
import { InnerTitleSectionModel } from "../ui-models/InnerTitleSectionModel";
import { BecomeAPartnerFormModel } from "../ui-models/BecomeAPartnerFormModel";
import { FooterSectionModel } from "../ui-models/FooterSectionModel";
import { OurProductSectionModel } from "../ui-models/OurProdcutsSectionSectionModel";
import { CollectionSectionModel } from "../ui-models/CollectionSectionModel";
import { DetailSectionModel } from "../ui-models/BlogDetailSectionModel";
import { TeamCarouselSectionModel } from "../ui-models/TeamCarouselSectionModel";
import { SubsidiarySectionModel } from "../ui-models/SubsidarySectionModel";
import { OurPartnersSectionModel } from "../ui-models/OurPartnersSectionModel";
import { StatisticsSectionModel } from "../ui-models/StatisticsSectionModel";
import { OurNewsSectionModel } from "../ui-models/OurNewsSectionModel";
import { WhereWeAreSectionModel } from "../ui-models/WhereWeAreSectionModel";
import { OurStorySectionModel } from "../ui-models/OurStorySectionModel";
import { LatestNewsSectionModel } from "../ui-models/LatestNewsSectionModel";
import { PageNotFoundSectionModel } from "../ui-models/PageNotFoundSectionModel";
import { ContactUsFormModel } from "../ui-models/ContactUsFormModel";
import { PartnersPageModel } from "../ui-models/PartnersPageModel";
import { PipelineProductsSectionModel } from "../ui-models/PipelineProductsSectionModel";
import { ProductsPageModel } from "../ui-models/ProductsPageModel";
import { LocationSectionModel } from "../ui-models/LoactionSectionModel";
import HeroSection from "@/app/ui-kit/containers/hero-section";
import MainNavbar from "@/app/ui-kit/containers/main-navbar";
import WhoWeAreSection from "@/app/ui-kit/containers/who-we-are-section";
import InnerHeroSection from "@/app/ui-kit/containers/inner-hero-hero-section";
import FooterSection from "@/app/ui-kit/containers/footer-section";

interface IComponentModelPair {
  component: React.ComponentType<any> | undefined;
  modelClass: any;
}
interface IComponentsToBeRendered {
  [key: string]: IComponentModelPair;
}

const dynamicOptions = {
  loading: () => <div className="min-h-[500px]"></div>,
  ssr: false,
};
const SubsidiarySection = dynamic(
  () => import("@/app/ui-kit/containers/subsidary-section"),
  { ...dynamicOptions }
);
const TeamSectionCarousel = dynamic(
  () => import("@/app/ui-kit/containers/team-carousel-section"),
  { ...dynamicOptions }
);
const BlogsCollectionWrapperSection = dynamic(
  () => import("@/app/ui-kit/containers/blogs-collection-section/wrapper"),
  { ...dynamicOptions }
);
const BlogDetailSection = dynamic(
  () => import("@/app/ui-kit/containers/blog-detail-section")
);

const InnerServicesSection = dynamic(
  () => import("@/app/ui-kit/containers/inner-services-section"),
  { ...dynamicOptions }
);
const BecomePartnerForm = dynamic(
  () => import("@/app/ui-kit/containers/become-partner-form"),
  { ...dynamicOptions }
);

const OurProductsSection = dynamic(
  () => import("@/app/ui-kit/containers/our-products-section"),
  { ...dynamicOptions }
);
const SupplierCollectionSection = dynamic(
  () => import("@/app/ui-kit/containers/supplier-collection-section"),
  { ...dynamicOptions }
);
const OurPartnersSection = dynamic(
  () => import("@/app/ui-kit/containers/our-partners-section"),
  { ...dynamicOptions }
);

const InnerPageTitleSection = dynamic(
  () => import("@/app/ui-kit/containers/inner-page-title"),
  { ...dynamicOptions }
);

const OurServicesSection = dynamic(
  () => import("@/app/ui-kit/containers/our-services-section"),
  { ...dynamicOptions }
);
const OurSuppliersSection = dynamic(
  () => import("@/app/ui-kit/containers/our-suppliers-section"),
  { ...dynamicOptions }
);
const StatisticsSection = dynamic(
  () => import("@/app/ui-kit/containers/statistics-section"),
  { ...dynamicOptions }
);

const OurNewsSection = dynamic(
  () => import("@/app/ui-kit/containers/our-news-section"),
  { ...dynamicOptions }
);

const WhereWeAreSection = dynamic(
  () => import("@/app/ui-kit/containers/where-we-are-section"),
  { ...dynamicOptions }
);

const OurStorySecton = dynamic(
  () => import("@/app/ui-kit/containers/our-story-section"),
  { ...dynamicOptions }
);
const LatestNewsSection = dynamic(
  () => import("@/app/ui-kit/containers/latest-news-section"),
  { ...dynamicOptions }
);

const ContactUsForm = dynamic(
  () => import("@/app/ui-kit/containers/contact-us-form"),
  { ...dynamicOptions }
);

const PartnersPageSection = dynamic(
  () => import("@/app/ui-kit/containers/partners-page-section"),
  { ...dynamicOptions }
);

const PipelineProductsSection = dynamic(
  () => import("@/app/ui-kit/containers/pipeline-products-section"),
  { ...dynamicOptions }
);

const ProductsPage = dynamic(
  () => import("@/app/ui-kit/containers/products-page"),
  { ...dynamicOptions }
);
const ProductDetailsSection = dynamic(
  () => import("@/app/ui-kit/containers/products-detail-section"),
  { ...dynamicOptions }
);

const LocationSection = dynamic(
  () => import("@/app/ui-kit/containers/location-section"),
  { ...dynamicOptions }
);
const PageNotFoundSection = dynamic(
  () => import("@/app/ui-kit/containers/404-section"),
  { ...dynamicOptions }
);

export const componentsToBeRendered: IComponentsToBeRendered = {
  "medical-theme.medt-hero-section": {
    component: HeroSection,
    modelClass: HeroSectionModel,
  },
  "medical-theme.medt-navbar": {
    component: MainNavbar,
    modelClass: MainNavbarModel,
  },
  "medical-theme.medt-inner-hero-section": {
    component: InnerHeroSection,
    modelClass: InnerHeroSectionModel,
  },
  "medical-theme.medt-who-we-are-section": {
    component: WhoWeAreSection,
    modelClass: WhoWeAreSectionModel,
  },
  "medical-theme.medt-our-services-section": {
    component: OurServicesSection,
    modelClass: OurServicesSectionModel,
  },
  "medical-theme.medt-our-supplier-section": {
    component: OurSuppliersSection,
    modelClass: OurSuppliersSectionModel,
  },
  "medical-theme.medt-in-title-section": {
    component: InnerPageTitleSection,
    modelClass: InnerTitleSectionModel,
  },
  "medical-theme.medt-in-services-section": {
    component: InnerServicesSection,
    modelClass: OurServicesSectionModel,
  },
  "medical-theme.medt-partner-form": {
    component: BecomePartnerForm,
    modelClass: BecomeAPartnerFormModel,
  },
  "medical-theme.medt-footer-section": {
    component: FooterSection,
    modelClass: FooterSectionModel,
  },
  "medical-theme.medt-our-prod-section": {
    component: OurProductsSection,
    modelClass: OurProductSectionModel,
  },
  "medical-theme.medt-suppliers-c-section": {
    component: SupplierCollectionSection,
    modelClass: CollectionSectionModel,
  },
  "medical-theme.medt-partners-co-section": {
    component: OurPartnersSection,
    modelClass: OurPartnersSectionModel,
  },
  "medical-theme.medt-statistics-section": {
    component: StatisticsSection,
    modelClass: StatisticsSectionModel,
  },
  "medical-theme.medt-our-news-section": {
    component: OurNewsSection,
    modelClass: OurNewsSectionModel,
  },
  "medical-theme.medt-blog-list-section": {
    component: BlogsCollectionWrapperSection,
    modelClass: CollectionSectionModel,
  },
  "medical-theme.medt-blog-detail-section": {
    component: BlogDetailSection,
    modelClass: DetailSectionModel,
  },
  "medical-theme.medt-where-we-are": {
    component: WhereWeAreSection,
    modelClass: WhereWeAreSectionModel,
  },
  "medical-theme.medt-team-section": {
    component: TeamSectionCarousel,
    modelClass: TeamCarouselSectionModel,
  },
  "medical-theme.medt-subsidiary-section": {
    component: SubsidiarySection,
    modelClass: SubsidiarySectionModel,
  },

  "medical-theme.medt-our-story": {
    component: OurStorySecton,
    modelClass: OurStorySectionModel,
  },
  "medical-theme.medt-latest-news": {
    component: LatestNewsSection,
    modelClass: LatestNewsSectionModel,
  },
  "medical-theme.medt-404-page": {
    component: PageNotFoundSection,
    modelClass: PageNotFoundSectionModel,
  },

  "medical-theme.medt-contact-us-form": {
    component: ContactUsForm,
    modelClass: ContactUsFormModel,
  },

  "medical-theme.medt-partners-page": {
    component: PartnersPageSection,
    modelClass: PartnersPageModel,
  },

  "medical-theme.medt-pipeline": {
    component: PipelineProductsSection,
    modelClass: PipelineProductsSectionModel,
  },

  "medical-theme.medt-all-products": {
    component: ProductsPage,
    modelClass: ProductsPageModel,
  },
  "medical-theme.1-medt-product-details": {
    component: ProductDetailsSection,
    modelClass: DetailSectionModel,
  },

  "medical-theme.medt-location-section": {
    component: LocationSection,
    modelClass: LocationSectionModel,
  },
};

export const PageComponents = (data: any, pageId?: string) => {
  const { __component, id } = data;
  const key = __component;
  if (
    componentsToBeRendered[key] !== undefined &&
    typeof componentsToBeRendered[key] !== "undefined"
  ) {
    return React.createElement(componentsToBeRendered[key].component ?? "div", {
      key: `${key}-${id}`,
      ...data,
    });
  }
  return React.createElement("div", {
    key,
    props: null,
  });
};

export const renderUiModels = (data: any, pageId: string): any => {
  const models: any[] = [];
  const {
    data: {
      attributes: { components },
    },
  } = data;
  if (components)
    for (const item of components) {
      const key = item.__component;
      if (!componentsToBeRendered[key]) continue;
      const model = new componentsToBeRendered[key].modelClass(item);
      models.push(model);
    }
  return models;
};
