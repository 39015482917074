"use client";
import React from "react";
import {
  ICollectionSectionProps,
  ISupplierItemProps,
} from "../../interfaces/ui-interfaces";
import useCollectionHook from "@/helpers/collectionSectionHook";
import SupplierItem from "../../components/supplier-item";
import { MODEL_MAPPING } from "../../interfaces/enum";

export default function SupplierCollectionSection(
  props: Readonly<ICollectionSectionProps>
) {
  const { data, isLoading, error } = useCollectionHook({
    ...props.data,
    page: 1,
    modelClass: (MODEL_MAPPING as any)[props.data.type],
  });

  if (isLoading) return "loading...";

  if (!data || error) return null;
  return (
    <section className="px-5 pb-20  relative  overflow-hidden">
    
      <div className="container m-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-sp-25">
          {data.data?.map((item: ISupplierItemProps) => {
            return <SupplierItem key={item.id} {...item} />;
          })}
        </div>
      </div>

    </section>
  );
}
