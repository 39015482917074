import {
  IImageProps,
  IProductItemProps,
  IProductPackSize,
  IProductRelationProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import { ProductItemType, ProductRelationType } from "@/infrastructure/types";

export class ProductItemModel implements IProductItemProps {
  id: number;
  name: string;
  description: string;
  dosage?: number | undefined;
  isPipline?: boolean | undefined;
  packSize?: IProductPackSize[] | undefined;
  pipelineState?: string | undefined;
  pipelineExpectedDateTime?: string | undefined;
  productCategories?: IProductRelationProps[] | undefined;
  productCompositions?: IProductRelationProps[] | undefined;
  productForm?: IProductRelationProps[] | undefined;
  images: IImageProps[];

  constructor(data: ProductItemType) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.description = data.attributes.description;
    this.dosage = data.attributes.dosage;
    this.isPipline = data.attributes?.isPipeline;
    this.packSize = data.attributes.packSize;
    this.pipelineState = data.attributes.pipelineState;
    this.pipelineExpectedDateTime = data.attributes.pipelineExpectedDateTime;
    this.images =
      data?.attributes?.images?.data &&
      data.attributes.images.data.map((item: any) => {
        return {
          imageUrl: getImageUrl(item?.attributes, "medium"),
          imageAlt:
            item?.attributes?.alternativeText ?? item?.attributes?.name ?? "",
          imageMdUrl: getImageUrl(item?.attributes, "small"),
        };
      });
    this.productCategories = data.attributes?.product_categories?.data.map(
      (item: ProductRelationType) => {
        return {
          id: item.id,
          ...item.attributes,

          image: item?.attributes?.image && {
            imageUrl: getImageUrl(
              item?.attributes?.image?.data?.attributes,
              "medium"
            ),
            imageMdUrl: getImageUrl(
              item?.attributes?.image?.data?.attributes,
              "small"
            ),
            imageAlt:
              item?.attributes?.image?.data?.attributes?.alternativeText ??
              item?.attributes?.image?.data?.attributes?.name ??
              "",
          },
        };
      }
    );
    this.productCompositions = data.attributes?.product_compositions?.data.map(
      (item: ProductRelationType) => {
        return {
          id: item.id,
          ...item.attributes,

          image: item?.attributes?.image && {
            imageUrl: getImageUrl(
              item?.attributes?.image?.data?.attributes,
              "medium"
            ),
            imageMdUrl: getImageUrl(
              item?.attributes?.image?.data?.attributes,
              "small"
            ),
            imageAlt:
              item?.attributes?.image?.data?.attributes?.alternativeText ??
              item?.attributes?.image?.data?.attributes?.name ??
              "",
          },
        };
      }
    );
    this.productForm = data.attributes?.product_forms?.data.map(
      (item: ProductRelationType) => {
        return {
          id: item.id,
          ...item.attributes,

          image: item?.attributes?.image && {
            imageUrl: getImageUrl(
              item?.attributes?.image?.data?.attributes,
              "medium"
            ),
            imageMdUrl: getImageUrl(
              item?.attributes?.image?.data?.attributes,
              "small"
            ),
            imageAlt:
              item?.attributes?.image?.data?.attributes?.alternativeText ??
              item?.attributes?.image?.data?.attributes?.name ??
              "",
          },
        };
      }
    );
  }
}
