"use client";
import React, { useEffect, useRef, useState } from "react";
import classes from "./styles.module.scss";
import { IStatisticsSectionProps } from "../../interfaces/ui-interfaces";
import NumberCounter from "../../components/number-counter";
export default function StatisticsSection(props: IStatisticsSectionProps) {
  const { data } = props;

  return (
    <section
      className={
        "px-5 py-sp-10 my-12 bg-gradient-back blur-[50px]" +
        classes.backgroundcolor
      }
    >
      <div className="flex flex-col md:flex-row justify-center items-center">
        {data && data.length > 0 ? (
          data.map((item, index) => {
            return (
              <div
                key={item.id}
                className="flex flex-col md:flex-row justify-center items-center"
              >
                <div className="p-4 flex flex-row gap-1 justify-center items-center">
                  <NumberCounter value={+item.value} />
                  {index < data.length - 1 ? (
                    <span className="font-semibold text-MdWhite text-3xl">
                      +
                    </span>
                  ) : null}

                  <span className=" text-MdWhite text-xl">{item.title}</span>
                </div>

                {index < data.length - 1 ? (
                  <div className="h-[1px] w-[75px] md:w-[1px] md:h-[75px] my-6 md:my-0 md:mx-6 bg-MdWhite"></div>
                ) : null}
              </div>
            );
          })
        ) : (
          <span>No Statisitcs</span>
        )}
      </div>
    </section>
  );
}
