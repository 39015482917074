"use client";
import React, { useState } from "react";
import { IWhereWeAreProps } from "../../interfaces/ui-interfaces";
import MapSvg from "../../elements/svgs/map-svg";

export default function WhereWeAreSection(props: IWhereWeAreProps) {
  const { title, subTitle } = props;

  return (
    <section className="bg-MdLight px-5 my-12 py-8 ">
      <div className="container mx-auto ">
        <div className="flex flex-col xl:flex-row gap-y-11  justify-center items-center overflow-hidden">
          <div className="flex flex-row gap-sp-5">
            <div className="flex items-end">
              {/* <div className="bg-MdBlue w-[1px] h-[6rem] mx-1 "></div> */}
            </div>
            <div className="flex flex-col gap-y-sp-30">
              <h2 className="text-MdBlue text-s-50 leading-[4.5rem]">
                {title}
              </h2>
              <span className="text-MdDarkGrey font-semibold text-s-35 leading-normal">
                {subTitle}
              </span>
            </div>
          </div>

          <div className="flex justify-center overflow-hidden ">
            <MapSvg />
          </div>
        </div>
      </div>
    </section>
  );
}
