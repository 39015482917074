"use client";
import React, { useState } from "react";

export default function FilterSearchSvg() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        d="M7.4138 11.958C7.82801 11.958 8.1638 11.6222 8.1638 11.208C8.1638 10.7938 7.82801 10.458 7.4138 10.458V11.958ZM0.747131 10.458C0.332918 10.458 -0.00286859 10.7938 -0.00286865 11.208C-0.00286871 11.6222 0.332918 11.958 0.747131 11.958L0.747131 10.458ZM7.4138 2.54134C6.99958 2.54134 6.6638 2.87713 6.6638 3.29134C6.6638 3.70555 6.99958 4.04134 7.4138 4.04134V2.54134ZM14.0805 4.04134C14.4947 4.04134 14.8305 3.70556 14.8305 3.29134C14.8305 2.87713 14.4947 2.54134 14.0805 2.54134V4.04134ZM7.4138 10.458L0.747131 10.458L0.747131 11.958L7.4138 11.958V10.458ZM7.4138 4.04134L14.0805 4.04134V2.54134L7.4138 2.54134V4.04134ZM-0.00286865 3.29134C-0.00286865 4.61579 1.11582 5.62467 2.4138 5.62467V4.12467C1.87082 4.12467 1.49713 3.71579 1.49713 3.29134H-0.00286865ZM2.4138 5.62467C3.71177 5.62467 4.83046 4.61579 4.83046 3.29134H3.33046C3.33046 3.71579 2.95677 4.12467 2.4138 4.12467V5.62467ZM4.83046 3.29134C4.83046 1.96689 3.71177 0.958008 2.4138 0.958008V2.45801C2.95677 2.45801 3.33046 2.86689 3.33046 3.29134H4.83046ZM2.4138 0.958008C1.11582 0.958008 -0.00286865 1.96689 -0.00286865 3.29134H1.49713C1.49713 2.86689 1.87082 2.45801 2.4138 2.45801V0.958008ZM14.8305 11.208C14.8305 9.88356 13.7118 8.87467 12.4138 8.87467V10.3747C12.9568 10.3747 13.3305 10.7836 13.3305 11.208H14.8305ZM12.4138 8.87467C11.1158 8.87467 9.99713 9.88356 9.99713 11.208H11.4971C11.4971 10.7836 11.8708 10.3747 12.4138 10.3747V8.87467ZM9.99713 11.208C9.99713 12.5325 11.1158 13.5413 12.4138 13.5413V12.0413C11.8708 12.0413 11.4971 11.6325 11.4971 11.208H9.99713ZM12.4138 13.5413C13.7118 13.5413 14.8305 12.5325 14.8305 11.208H13.3305C13.3305 11.6325 12.9568 12.0413 12.4138 12.0413V13.5413Z"
        className={isHovered ? "fill-MdBlue" : "fill-[#39393A]"}
      />
    </svg>
  );
}
