"use clinet";
import React, { useState } from "react";
import { IBlogItemProps } from "../../interfaces/ui-interfaces";
import classes from "./styles.module.scss";
import slugify from "slugify";
import ImageNextHelper from "../../elements/image-next-helper";
export default function NewsCard(props: IBlogItemProps) {
  const { title, date, image, description, id } = props;
  const [showContent, setShowContent] = useState(false);
  const split = description.split(" ").slice(0, 10);
  let summing = "";
  split.map((word) => {
    summing += word + " ";
  });
  return (
    <div
      className={` h-[250px] overflow-hidden relative`}
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
    >
      <ImageNextHelper
        src={image?.imageUrl}
        fill
        objectClass="object-cover object-center"
        alt={image?.imageAlt ?? "alt-" + slugify(title)}
        className="z-0"
        placeholder="blur"
        blurDataURL={image?.imageUrl}
      />
      <div
        className={`h-full relative z-10 bg-[#0118394D] hover:!bg-[#011839e6] `}
      >
        <div className={` h-full  p-6   ${classes.textbg}`}>
          <div
            className={`flex   flex-col gap-y-sp-10  ${
              showContent ? "" : "justify-end"
            }  h-full transition-all ease-in-out duration-400  `}
          >
            <div
              className={`${
                showContent ? " " : "grow"
              }  transition-all ease-in-out duration-400 `}
            ></div>
            <div
              className={`flex   flex-col gap-y-sp-10 ${
                showContent ? "grow " : ""
              }  transition-all ease-in-out duration-400`}
            >
              <span className="text-MdWhite text-s-10 font-light w-fit  leading-normal bg-MdBlueTransparent  p-3">
                {date}
              </span>

              <div className="text-MdWhite text-s-18 font-semibold leading-normal pb-sp-10 border-b-2 border-MdBlue">
                {title}
              </div>
              <article
                className={`text-MdWhite text-s-12 leading-normal transition-all ease-in-out duration-600 ${
                  showContent ? "block" : "hidden"
                }`}
              >
                {summing.length < description.length ? summing + ", " : summing}
                {summing.length < description.length ? (
                  <span className=" text-mp-12 text-MdBlue ">read more...</span>
                ) : null}
              </article>
            </div>
          </div>
        </div>
        <a
          className="absolute w-full h-full z-20 top-0 bottom-0 right-0 left-0"
          aria-label={title}
          href={`/${props.type}/${id}/${slugify(title)}`}
        ></a>
      </div>
    </div>
  );
}
