"use client";
import { useState } from "react";
import { MedlinkBadge, MedlinkLogo } from "../../elements/svgs/medlinkLogo";
import MainButton from "../../elements/main-btn";
import { IMainNavbarProps } from "../../interfaces/ui-interfaces";
import classes from "./styles.module.scss";
import Image from "next/image";
import ListSvg from "../../elements/svgs/navSvgs/listSvg";
import { contentFullImageLoader } from "@/helpers/imageLoader";
import { ToastContainer } from "react-toastify";
import ImageNextHelper from "../../elements/image-next-helper";
const MainNavbar = (props: IMainNavbarProps) => {
  const [toggleNav, setToggleNav] = useState(false);
  const { items, logo, callToActionBtn } = props;
  const menuItems = items;

  return (
    <nav className={`w-full fixed z-50 pt-4 px-5  `}>
      <div
        className={`container mx-auto    ${classes.navBg} flex flex-row justify-between items-center p-4`}
      >
        <div className="flex flex-row gap-x-[6px] ">
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            aria-controls="navbar-default"
            aria-expanded={toggleNav ? "true" : "false"}
            onClick={() => setToggleNav(!toggleNav)}
            className="block lg:hidden p-sp-5 "
          >
            <span className="sr-only">Open main menu</span>
            <ListSvg />
          </button>

          <div className="hidden sm:block">
            {/* <MedlinkBadge /> */}
            {logo ? (
              <a href="/" aria-label="home">
                {" "}
                <ImageNextHelper
                  src={logo}
                  width={100}
                  height={60}
                  alt="almahara Logo"
                  className="h-[60px] w-[100px]"
                />
              </a>
            ) : null}
          </div>
        </div>

        <div
          className={`hidden w-full lg:block lg:w-auto `}
          id="navbar-default"
        >
          <ul
            className={` font-Poppins text-s-14 text-white    flex flex-col  lg:flex-row gap-2 md:gap-4 
              
            `}
          >
            {menuItems.map((item) => {
              return (
                <li key={item.id} className="p-2 hover:cursor-pointer">
                  <a href={item.link} aria-label={"nav-" + item.title}>
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        {callToActionBtn ? (
          <MainButton
            title={callToActionBtn.label ?? "Become Partner"}
            className="!w-[165px]"
            onClick={() => {
              if (callToActionBtn.link)
                window.location.href = callToActionBtn.link;
            }}
          />
        ) : null}
      </div>

      <div
        className={`  ${toggleNav ? `block` : "hidden"} ${
          classes.navBg
        } rounded-b-lg  lg:hidden lg:w-auto    container mx-auto`}
        id="navbar-default"
      >
        <ul
          className={` ${
            toggleNav ? `block` : "hidden"
          }  font-Poppins text-s-14 text-white p-4     flex flex-col  lg:flex-row gap-2 
              
            `}
        >
          {menuItems.map((item) => {
            return (
              <li key={item.id} className="p-2 hover:cursor-pointer">
                <a href={item.link} aria-label={"menu-nav-" + item.title}>
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <ToastContainer />
    </nav>
  );
};

export default MainNavbar;
