"use client";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import React, { useState } from "react";
import Arrows from "../../elements/keen-elements/arrows";
import {
  IOurProductsSectionProps,
  IProductRelationProps,
} from "../../interfaces/ui-interfaces";
import SecondaryBtn from "../../elements/secondary-btn";
import ProductsCategoryCard from "../../components/products-cat-card";
import { ResizePlugin } from "@/helpers/resize-keen";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";

export default function OurProductsSection(props: IOurProductsSectionProps) {
  const { title, data, callToActionBtn } = props;

  const {
    data: productsData,
    isLoading,
    error,
  } = useCollectionHook({
    ...props.data,
    page: 1,
    modelClass: (MODEL_MAPPING as any)[props.data.type],
  });

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 2,
      loop: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },

      drag: true,

      breakpoints: {
        "(min-width: 640px)": {
          slides: { perView: 2, spacing: 15, origin: "center" },
        },
        "(min-width: 768px)": {
          slides: { perView: 3, spacing: 15, origin: "center" },
        },
        "(min-width: 1024px)": {
          slides: { perView: 4, spacing: 15, origin: "center" },
        },
        "(min-width: 1280px)": {
          slides: { perView: 5, spacing: 15, origin: "center" },
        },
      },
      slides: { perView: 1, spacing: 20, origin: "center" },
    },
    [ResizePlugin]
  );

  return (
    <section className="px-5 my-12 py-20">
      <div className="container mx-auto flex flex-col gap-y-11 py-5">
        <h1 className="text-center text-MdBlue text-s-50  ">{title}</h1>

        {productsData && productsData.data.length > 0 ? (
          <div className="relative flex justify-center ">
            <div className="flex flex-col justify-center">
              {loaded && instanceRef.current && (
                <>
                  <Arrows
                    onClick={(e: any) =>
                      e.stopPropagation() || instanceRef.current?.prev()
                    }
                    disabled={currentSlide === 0}
                    left={true}
                    hiddenOnSmall={false}
                  />
                </>
              )}
            </div>

            <div ref={ref} className="keen-slider ">
              {productsData && productsData.data.length > 0
                ? productsData.data.map((item: IProductRelationProps) => {
                    // if (item.title != "Injectables") {
                    return (
                      <div key={item.id} className={`keen-slider__slide`}>
                        <ProductsCategoryCard
                          id={item.id}
                          title={item.title}
                          image={item.image}
                        />
                      </div>
                    );
                    // }
                  })
                : null}
            </div>

            {/* <div className="absolute top-0 flex justify-center">
              <div className=" w-[200px] h-[165px] ">
                <ProductsCategoryCard
                  id={0}
                  title={"Injectables"}
                  image={
                    "http://54.74.95.139/uploads/syringe_d82f089dc7.svg?w=128"
                  }
                />
              </div>
            </div> */}

            <div className="flex items-center">
              {loaded && instanceRef.current && (
                <>
                  <Arrows
                    onClick={(e: any) =>
                      e.stopPropagation() || instanceRef.current?.next()
                    }
                    disabled={
                      currentSlide ===
                      instanceRef?.current?.track?.details?.maxIdx
                    }
                    left={false}
                    hiddenOnSmall={false}
                  />
                </>
              )}
            </div>
          </div>
        ) : null}

        <div className="flex justify-center">
          <SecondaryBtn title={callToActionBtn?.label ?? ""} link="/products" />
        </div>
      </div>
    </section>
  );
}
