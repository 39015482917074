/* eslint-disable jsx-a11y/alt-text */

"use client";
import {
  IHeroSectionProps,
  IHomeBannerProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import React from "react";
import classes from "./styles.module.scss";

import {
  easeInOut,
  motion,
  useAnimation,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { Slice1, Slice2 } from "@/app/ui-kit/elements/svgs/bannerSvgs";

import MainButton from "@/app/ui-kit/elements/main-btn";
import Image from "next/image";
import ImageNextHelper from "../../elements/image-next-helper";

export default function HeroSection(props: IHeroSectionProps) {
  const { title, caption, callToActionBtn } = props;

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (
      latest < 600 &&
      ((latest / 100) % 2 == 0 || (latest / 10) % 2 == 0 || latest % 2 == 0)
    ) {
      controls.start({
        x: -10,
        y: 10,
        transition: { duration: 0.05, ease: "easeInOut" },
      });
      controls2.start({
        x: -10,
        y: 10,
        transition: { duration: 0.2, ease: "easeInOut" },
      });
    } else {
      controls.start({
        x: 0,
        y: 0,
        transition: { duration: 0.05, ease: "easeInOut" },
      });
      controls2.start({
        x: 0,
        y: 0,
        transition: { duration: 0.2, ease: "easeInOut" },
      });
    }
  });

  const controls = useAnimation();
  const controls2 = useAnimation();
  const handleMouseMove = (e: any) => {
    const { clientX, clientY } = e;
    const moveX = clientX - window.innerWidth / 2;
    const moveY = clientY - window.innerHeight / 2;
    const offsetFactorX = 20;
    const offsetFactorY = 30;
    controls.start({
      x: -moveX / offsetFactorX,
      y: moveY / offsetFactorY,
      transition: { duration: 0.05, ease: "easeInOut" },
    });
    controls2.start({
      x: -moveX / offsetFactorX,
      y: moveY / offsetFactorY,
      transition: { duration: 0.2, ease: "easeInOut" },
    });
  };
  const leave = (e: any) => {
    controls.start({
      x: 0,
      y: 0,
      scale: 1,
    });
    controls2.start({
      x: 0,
      y: 0,
      scale: 1,
    });
  };
  return (
    <section>
      <motion.div className="relative">
        <motion.div
          className={` h-[500px] sm:h-[611px]   relative  overflow-hidden ${classes.back} ${classes.slice0} `}
        >
          <div className="container mx-auto px-5 pt-40 flex flex-col  gap-y-5">
            <div className="flex flex-col">
              <h1 className="font-Poppins text-MdWhite text-s-32 sm:text-s-50 font-extrabold">
                {title}
              </h1>
              <span className="font-Poppins text-MdWhite text-s-32 sm:text-s-50 font-normal">
                {caption}
              </span>
            </div>

            {/* {callToActionBtn ? (
              <MainButton
                className="!w-[165px]"
                onClick={() => {
                  window.location.href = callToActionBtn.link;
                }}
                title={callToActionBtn?.label ?? ""}
              />
            ) : null} */}
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
}
