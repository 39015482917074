"use client";
import { IUInputFieldProps } from "@/app/ui-kit/interfaces/ui-interfaces";
// import classes from "./style.module.scss";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { parsePhoneNumber } from "awesome-phonenumber";

import React, { useEffect } from "react";
const PhoneInputField = (props: IUInputFieldProps) => {
  const {
    labelClassName,
    label,
    inputClassName,
    countrySelectorBtnClassName,
    required,
  } = props;

  const ref = React.useRef<any>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.id = props?.id;
    }
  }, [props?.id]);
  return (
    <div
      className={`flex flex-col gap-sp-10 w-full relative  ${
        labelClassName ?? ""
      }`}
    >
      <label
        htmlFor={props?.id}
        className="text-s-16 text-MdBlue leading-normal px-sp-10 "
      >
        {label}
      </label>
      <PhoneInput
        ref={ref}
        defaultCountry={"iq"}
        value={props.value}
        onChange={(phone) => {
          const pn = parsePhoneNumber(phone);

          if (props?.setValue) {
            if (pn.valid) {
              props?.setValue(phone);
              if (props?.setPhone) {
                props?.setPhone(phone);
              }
            } else {
              props?.setValue("");
            }
          }
        }}
        className="z-10 "
        inputClassName={`w-full text-MdGrayLight text-s-12 focus:border-MdBlue  !border-[1px] border-MdInputBorder p-1 ${
          inputClassName ?? ""
        }`}
        countrySelectorStyleProps={{
          buttonClassName: `!border-MdGrey !px-1  ${countrySelectorBtnClassName}`,
        }}
        required={required}
      />
    </div>
  );
};

export default PhoneInputField;
