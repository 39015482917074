"use client";
import React, { useEffect, useRef, useState } from "react";
import {
  IOurStorySectionProps,
  IStoryItemProps,
} from "../../interfaces/ui-interfaces";
import StoryItem from "../../components/story-item";
import { motion, useAnimation, useInView } from "framer-motion";
import classes from "./styles.module.scss";

export default function OurStorySecton(props: IOurStorySectionProps) {
  const { title, data } = props;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const mainStoryControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("end");
      mainStoryControls.start("end");
    }
  }, [isInView, mainControls]);

  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (scrollableDivRef.current) {
      setDragging(true);
      setStartX(e.pageX - scrollableDivRef.current.offsetLeft);
      setScrollLeft(scrollableDivRef.current.scrollLeft);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!dragging || !scrollableDivRef.current) return;

    const x = e.pageX - scrollableDivRef.current.offsetLeft;
    const distance = x - startX;
    scrollableDivRef.current.scrollLeft = scrollLeft - distance;
  };

  return (
    <section
      className="px-5 my-12 relative z-40 py-20"
      style={{
        background: "url(/assets/images/story-bg.svg) no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div ref={ref} className="container mx-auto">
        <h2 className="text-MdBlue text-s-50 leading-[4.5rem] text-center mb-11">
          {title}
        </h2>

        <div className="w-full   ">
          <div
            ref={scrollableDivRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseUp}
            className={`flex flex-col   w-full overflow-x-scroll ${
              classes.noScrollbar
            } ${dragging ? "cursor-grabbing" : "cursor-grab"}`}
          >
            <div className="flex flex-row">
              {data
                ? data.map((item: IStoryItemProps, index: number) => {
                    if (index % 2 == 0) {
                      return (
                        <div
                          key={item.id}
                          className={` !min-w-[220px] md:!min-w-[280px] relative z-30 flex  `}
                        >
                          <motion.div
                            variants={{
                              start: { opacity: 0 },
                              end: { opacity: 1 },
                            }}
                            initial="start"
                            animate={mainControls}
                            transition={{
                              duration: 0.5,
                              delay: index,
                              ease: "easeInOut",
                            }}
                            className="flex items-end justify-end"
                          >
                            <StoryItem
                              date={item.date}
                              title={item.title}
                              description={item.description}
                              image={item.image}
                              index={index}
                              id={item.id}
                            />
                          </motion.div>

                          {index !== data.length - 1 ? (
                            <motion.div
                              variants={{
                                start: { width: 0 },
                                end: { width: "100%" },
                              }}
                              initial="start"
                              animate={mainControls}
                              transition={{
                                duration: 0.5,
                                delay: 0.5 + index,
                                ease: "linear",
                              }}
                              className={`absolute bottom-5 left-5  -z-20  h-[2px] bg-gradient-back`}
                            ></motion.div>
                          ) : null}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index + "void"}
                          className=" !min-w-[220px] md:!min-w-[280px]"
                        ></div>
                      );
                    }
                  })
                : null}
            </div>
            <div className="flex flex-row -mt-11">
              {data
                ? data.map((item: IStoryItemProps, index: number) => {
                    if (index % 2 == 1) {
                      return (
                        <div
                          key={item.id}
                          className={` !min-w-[220px] md:!min-w-[280px] relative z-30 flex `}
                        >
                          <motion.div
                            variants={{
                              start: { opacity: 0 },
                              end: { opacity: 1 },
                            }}
                            initial="start"
                            animate={mainControls}
                            transition={{
                              duration: 0.5,
                              delay: index,
                              ease: "easeInOut",
                            }}
                          >
                            <StoryItem
                              date={item.date}
                              title={item.title}
                              description={item.description}
                              image={item.image}
                              index={index}
                              id={item.id}
                            />
                          </motion.div>

                          {index !== data.length - 1 ? (
                            <motion.div
                              variants={{
                                start: { width: 0 },
                                end: { width: "100%" },
                              }}
                              initial="start"
                              animate={mainControls}
                              transition={{
                                duration: 0.5,
                                delay: 0.5 + index,
                                ease: "linear",
                              }}
                              className={`absolute top-[1.38rem] left-5  -z-20  h-[2px] bg-gradient-back`}
                            ></motion.div>
                          ) : null}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={index + "void"}
                          className=" !min-w-[220px] md:!min-w-[280px]"
                        ></div>
                      );
                    }
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
