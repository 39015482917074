import React from "react";
import { IContactInfoProps } from "../../interfaces/ui-interfaces";

interface indexProps {
  index: number;
}

export default function ContactInfo(props: IContactInfoProps & indexProps) {
  return (
    <div
      className={`flex flex-row  gap-x-5 my-sp-25 justify-center sm:justify-start   ${
        props.index % 2 == 0 ? " lg:pr-sp-25 " : " lg:pl-sp-25 "
      } `}
    >


      <div className="flex flex-col sm:flex-row gap-y-4 gap-x-11">
        <div
          className="text-MdMainGray  leading-[1.375rem] "
          dangerouslySetInnerHTML={{ __html: props.location }}
        />

        <div className="text-MdMainGray leading-[1.375rem]">
          <div className="text-s-16 font-semibold mb-sp-10 ">{props.title}</div>
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: props.contact }}
          />
        </div>
      </div>


    </div>
  );
}
