"use client";
import React from "react";
import { IPageNotFoundSectionProps } from "../../interfaces/ui-interfaces";
import MainButton from "../../elements/main-btn";

export default function PageNotFoundSection(props: IPageNotFoundSectionProps) {
  const { title, description, callToActionBtn } = props;

  return (
    <section className="px-5 min-h-[70vh] flex justify-center relative overflow-hidden">
      <div className="container m-auto">
        <div className="flex flex-col gap-8 justify-center items-center">
          <div className="text-[9.375rem] font-bold text-[#387DC0] leading-[100%]">
            {title}
          </div>
          <div className="text-[1.25rem] text-MdBlue ">{description}</div>
          {callToActionBtn ? (
            

            <MainButton
              type="button"
              className="!w-[165px]"
              onClick={() => {
                window.location.href = callToActionBtn.link;
              }}
              title={callToActionBtn.label ?? ""}
            />
            
          ) : null}
        </div>
      </div>

    </section>
  );
}
