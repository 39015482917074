import { BlogItemModel } from "@/infrastructure/models/ui-models/BlogItemModel";
import { PartnerItemModel } from "@/infrastructure/models/ui-models/PartnerItemModel";
import { ProductCompositionModel } from "@/infrastructure/models/ui-models/ProductCompositionsModel";
import { ProductItemModel } from "@/infrastructure/models/ui-models/ProductItemModel";
import { ProductRelationItemModel } from "@/infrastructure/models/ui-models/ProductRelationItemModel";
import { SupplierItemModel } from "@/infrastructure/models/ui-models/SupplierItemModel";

export enum WeightEnum {
  extrabold = "extrabold",
  semibold = "semibold",
  bold = "bold",
  medium = "medium",
  normal = "normal",
}

export enum StagesEnum {
  UnderPreparation = "Under Preparation",
  UnderRegistration = "Under Registration",
  MAStatus = "MA Status / Q1",
}
export const enum STAGECOLOR {
  UnderPreparation = "#50B29D",
  UnderRegistration = "#157CDF",
  MAStatus = "#57B251",
}

export const stagesArray = Object.keys(StagesEnum).map((key) => ({
  key,
  value: StagesEnum[key as keyof typeof StagesEnum],
}));

export enum ColorsEnum {
  MdWhite = "MdWhite",
  MdBlue = "MdBlue",
  MdGreen = "MdGreen",
}
export enum PAGES_SLUGS {
  HOME_PAGE = "home",
  SERVICES_PAGE = "services",
  SUPPLIERS_PAGE = "suppliers",
  BLOGS_PAGE = "blogs",
  PRODUCTS_CATEGORY = "product-categories",
  PRODUCTS_PAGE = "products",
  PARTNERS_PAGE = "partners",
  NEWS_PAGE = "news",
  NOT_FOUND = "404",
  PRODUCTS_COMPOSITION = "product-compositions",
  PRODUCTS_FORM = "product-forms",
}
type ModelMapping = {
  [PAGES_SLUGS.BLOGS_PAGE]: typeof BlogItemModel;
  [PAGES_SLUGS.SUPPLIERS_PAGE]: typeof SupplierItemModel;
  [PAGES_SLUGS.PARTNERS_PAGE]: typeof PartnerItemModel;
  [PAGES_SLUGS.PRODUCTS_CATEGORY]: typeof ProductRelationItemModel;
  [PAGES_SLUGS.PRODUCTS_PAGE]: typeof ProductItemModel;
  [PAGES_SLUGS.PRODUCTS_COMPOSITION]: typeof ProductCompositionModel;
  [PAGES_SLUGS.PRODUCTS_FORM]: typeof ProductRelationItemModel;
};

export const MODEL_MAPPING: ModelMapping = {
  [PAGES_SLUGS.BLOGS_PAGE]: BlogItemModel,
  [PAGES_SLUGS.SUPPLIERS_PAGE]: SupplierItemModel,
  [PAGES_SLUGS.PARTNERS_PAGE]: PartnerItemModel,
  [PAGES_SLUGS.PRODUCTS_CATEGORY]: ProductRelationItemModel,
  [PAGES_SLUGS.PRODUCTS_PAGE]: ProductItemModel,
  [PAGES_SLUGS.PRODUCTS_COMPOSITION]: ProductCompositionModel,
  [PAGES_SLUGS.PRODUCTS_FORM]: ProductRelationItemModel,
};

export enum COLLECTIONS {
  SUPPLIERS = "suppliers",
  BLOGS = "blogs",
  PARTNERS = "partners",
  NEWS = "news",
  PRODUCTcATEGORY = "product-categories",
  PRODUCTS = "products",
  PRODUCTS_COMPOSITION = "product-compositions",
  PRODUCTS_FORM = "product-forms",
}
