"use client";
import React from "react";
import { IInnerHeroSectionProps } from "../../interfaces/ui-interfaces";


export default function InnerHeroSection(
  props: Readonly<IInnerHeroSectionProps>
) {
  const { title } = props;
  return (
    <section className="h-[283px] 2xl:h-[320px] relative bg-gradient-back ">
      <div className="px-5  h-full relative z-40">
        <div className="container m-auto flex flex-col justify-center h-full pt-20 2xl:pt-5">
          <h1 className="text-[3.125rem] text-white font-bold">{title}</h1>
        </div>
      </div>{" "}
    </section>
  );
}
