import React from "react";
import { IBtnProps } from "../../interfaces/ui-interfaces";
export default function SecondaryBtn(props: IBtnProps) {
  const { title, link } = props;
  return (
    <a href={link}>
      <button
        className={`font-Poppins text-s-14 xs:text-s-16 text-white p-2 xs:p-4 rounded-md bg-MdBlue hover:bg-MdBlue1 `}
      >
        {title}
      </button>
    </a>
  );
}
