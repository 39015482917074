"use client";
import React, { useState } from "react";
import { IBlogItemProps, IOurNewsProps } from "../../interfaces/ui-interfaces";
import ViewMoreBtn from "../../elements/view-more-btn";
import NewsCard from "../../components/news-card";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { ResizePlugin } from "@/helpers/resize-keen";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
export default function OurNewsSection(props: IOurNewsProps) {
  const { title, subTitle, caption, callToActionBtn, data } = props;

  const { data: NewsData } = useCollectionHook({
    pageSize: props.data?.pageSize,
    page: 1,
    type: props.data?.type,
    modelClass: (MODEL_MAPPING as any)[props?.data?.type],
  });
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,

      created() {
        setLoaded(true);
      },
      breakpoints: {
        "(min-width: 440px)": {
          slides: { perView: 1.8, spacing: 30 },
        },
        "(min-width: 640px)": {
          slides: { perView: 2.5, spacing: 30 },
        },
        "(min-width: 768px)": {
          slides: { perView: 2.8, spacing: 30 },
        },
        "(min-width: 900px)": {
          slides: { perView: 3.2, spacing: 30 },
        },
        "(min-width: 1024px)": {
          slides: { perView: 3.5, spacing: 30 },
        },
        "(min-width: 1280px)": {
          slides: { perView: 3.8, spacing: 30 },
        },
      },
      slides: { perView: 1, spacing: 10 },
    },
    [ResizePlugin]
  );
  return (
    <section className="px-5 my-12 py-10">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-11  py-2">
          <div className="flex flex-col gap-y-sp-30 text-center w-full lg:max-w-[735px] mx-auto">
            <h2 className="text-MdBlue text-s-50 leading-[4.5rem]">{title}</h2>

            <div>
              <span className="block text-MdDarkGrey font-semibold text-s-35 leading-normal pb-5 mb-5 border-b-2 border-MdBlue">
                {subTitle}
              </span>

              <p className="text-MdDarkGrey text-s-18">{caption}</p>
            </div>
          </div>

          <div className="relative ">
            <div ref={sliderRef} className="keen-slider ">
              {NewsData &&
              NewsData?.data &&
              NewsData.data.length > 0 &&
              loaded ? (
                NewsData.data.map((item: IBlogItemProps) => {
                  return (
                    <div
                      key={"news-" + item.id}
                      className="keen-slider__slide h-[280px]"
                    >
                      <NewsCard
                        id={item.id}
                        date={item.date}
                        title={item.title}
                        content={item.content}
                        image={item.image}
                        description={item.description}
                        type={props.data.type}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="text-MdDarkGrey text-s-20 text-center font-semibold w-full">
                  Loading...
                </div>
              )}
            </div>
          </div>

          <div className="flex p-sp-10 justify-center sm:justify-end  ">
            <ViewMoreBtn
              label={callToActionBtn?.label ?? ""}
              link={callToActionBtn?.link ?? ""}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
