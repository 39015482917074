import React from "react";

export interface IDotsProps {
  instanceRef: any;
  currentSlide: number;
  className?: string;
  dots?: { selected: string; notSelected: string };
}

export default function Dots(props: IDotsProps) {
  const { instanceRef, currentSlide, className, dots } = props;
  return (
    <div className={`flex  pt-3 flex-row justify-center ${className ?? ""}`}>
      {Array.from(
        { length: instanceRef.current.track.details?.slides.length },
        (_, idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              className={
                `w-2 h-2  ${
                  dots ? "bg-" + dots.notSelected : "bg-MdBlue"
                } rounded-full cursor-pointer p-[0.45rem] mx-2  ` +
                (currentSlide === idx
                  ? dots
                    ? "!bg-[#929292]"
                    : "!bg-MdBlue"
                  : "")
              }
            ></button>
          );
        }
      )}
    </div>
  );
}
