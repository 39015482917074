"use client";
import React, { useEffect, useState } from "react";

import MainButton from "../../elements/main-btn";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { ResizePlugin } from "@/helpers/resize-keen";
import {
  IOurPartnersSectionProps,
  ISupplierItemProps,
} from "../../interfaces/ui-interfaces";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import { motion } from "framer-motion";
import ImageNextHelper from "../../elements/image-next-helper";

const animation = { duration: 10000, easing: (t: number) => t };

export default function OurPartnersSection(props: IOurPartnersSectionProps) {
  const { title, subTitle, callToActionBtn } = props;

  const { data: partnerData, isSuccess } = useCollectionHook({
    ...props.data,
    page: 1,
    type: props.data?.type,
    modelClass: (MODEL_MAPPING as any)[props.data.type],
  });

  const [loaded, setLoaded] = useState(false);

  const [items, setItems] = useState<ISupplierItemProps[]>([]);

  const variants = {
    start: { opacity: 0, y: 200 },
    end: { opacity: 1, y: 0 },
  };
  const transition = { duration: 0.5, ease: "easeInOut" };

  useEffect(() => {
    if (loaded && isSuccess) {
      setItems(partnerData?.data ?? []);
    }
  }, [loaded, isSuccess, partnerData]);

  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      loop: true,
      renderMode: "performance",
      drag: true,

      breakpoints: {
        "(min-width: 640px)": {
          slides: {
            perView: items.length < 4.5 ? items.length : 4.5,
            spacing: 42,
          },
        },
        "(min-width: 768px)": {
          slides: {
            perView: items.length < 5.5 ? items.length : 5.5,
            spacing: 42,
          },
        },
        "(min-width: 1024px)": {
          slides: {
            perView: items.length < 7.5 ? items.length : 7.5,
            spacing: 42,
          },
        },
        "(min-width: 1280px)": {
          slides: {
            perView: items.length < 7.5 ? items.length : 7.5,
            spacing: 42,
          },
        },
      },
      slides: { perView: items.length < 2 ? items.length : 2, spacing: 42 },

      created(s) {
        setLoaded(true);
        s.moveToIdx(items.length - 1, true, animation);
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs + (items.length - 1), true, animation);
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs + (items.length - 1), true, animation);
      },
    },
    [ResizePlugin]
  );

  return (
    <motion.section
      variants={variants}
      initial="start"
      transition={transition}
      whileInView="end"
      viewport={{ once: true }}
      className="px-5 my-12 py-20"
    >
      <div className="container mx-auto flex flex-col gap-y-11">
        <div className="flex flex-col gap-y-8 items-center">
          <h2 className="text-s-50 text-MdBlue  text-center ">{title}</h2>
          <span className="font-semibold text-s-32 text-MdDarkGrey text-center border-b-2 max-w-fit   border-MdBlue pb-5 ">
            {subTitle}
          </span>
        </div>

        <div ref={sliderRef} className="keen-slider  ">
          {loaded && items && items.length > 0 ? (
            items.map((item: ISupplierItemProps) => {
              return (
                <div
                  key={item.id}
                  className="keen-slider__slide flex   justify-center items-center relative"
                >
                  <ImageNextHelper
                    src={item?.image?.imageUrl}
                    width={90}
                    height={90}
                    alt={item?.image?.imageAlt ?? `image-${item.title}`}
                  />
                </div>
              );
            })
          ) : (
            <span className="mx-auto font-semibold">Loading ... </span>
          )}
        </div>

        {/* <div className="flex justify-center">
          {callToActionBtn ? (
            <MainButton
              className="!w-[165px]"
              title={callToActionBtn?.label ?? ""}
              onClick={() => {
                window.location.href = callToActionBtn?.link;
              }}
            />
          ) : null}
        </div> */}
      </div>
    </motion.section>
  );
}
