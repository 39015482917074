"use client";
import React from "react";
import {
  IBlogItemProps,
  IDetailSectionProps,
} from "../../interfaces/ui-interfaces";
import useDetailsSectionHook from "@/helpers/detailsSectionHook";
import { useParams } from "next/navigation";
import { MODEL_MAPPING } from "../../interfaces/enum";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
} from "react-share";
import slugify from "slugify";
import ImageNextHelper from "../../elements/image-next-helper";
export default function BlogDetailSection(props: IDetailSectionProps) {
  const params = useParams();
  const id = params?.slug && params?.slug.length >= 2 ? params.slug[1] : 0;
  const { data: details } = useDetailsSectionHook({
    pageId: id,
    modelClass: (MODEL_MAPPING as any)[props.data.type],
    type: props.data.type,
  });
  const data: IBlogItemProps = details;
  const url =
    typeof window !== "undefined"
      ? `${window.location.hostname}/blogs/${data.id}/${slugify(data.title)}`
      : "";
  return (
    <section className="px-5 mt-20 py-10">
      <div className="container m-auto">
        <div className="flex flex-col gap-8">
          <div className="relative min-h-[350px] rounded-[25px] overflow-hidden">
            {data?.image ? (
              <ImageNextHelper
                src={data?.image?.imageUrl ?? data?.image?.imageMdUrl ?? ""}
                fill
                objectClass="object-cover object-center"
                alt={data.title}
                placeholder="blur"
                blurDataURL={
                  data?.image?.imageMdUrl ?? data?.image?.imageUrl ?? undefined
                }
              />
            ) : null}
          </div>

          <div>
            <h2 className="text-[1.25rem] md:text-[2rem] text-MdMainGray font-[600] leading-normal  mb-2">
              {data.title}
            </h2>
            <div
              className="text-[0.75rem] text-MdDarkGrey leading-normal italic opacity-90 mb-6"
              dangerouslySetInnerHTML={{ __html: data?.description ?? "" }}
            />
            <div className="border-b-2    border-MdBlue"></div>
          </div>

          <article
            className="text-[0.875rem] text-MdDarkGrey leading-normal !font-Poppins px-4"
            dangerouslySetInnerHTML={{ __html: data?.content ?? "" }}
          />
          <div className="flex flex-row gap-4 px-4 mx-4 rounded-[0.3125rem]  items-center bg-MdLight">
            <span className="text-s-12 leading-normal text-MdMainGray">
              Share with
            </span>
            <div className="flex flex-row gap-2 p-sp-5   justify-start">
              <FacebookShareButton aria-label="facebook-share-link" url={url}>
                <FacebookIcon
                  size={24}
                  round={true}
                  bgStyle={{ fill: "#387DC0" }}
                />
              </FacebookShareButton>

              <TwitterShareButton url={url} aria-label="twitter-share-link">
                <XIcon size={24} round={true} bgStyle={{ fill: "#387DC0" }} />
              </TwitterShareButton>
              <LinkedinShareButton url={url} aria-label="linkedin-share-link">
                <LinkedinIcon
                  size={24}
                  round={true}
                  bgStyle={{ fill: "#387DC0" }}
                />
              </LinkedinShareButton>

              <WhatsappShareButton url={url} aria-label="whatsapp-share-link">
                <WhatsappIcon
                  size={24}
                  round={true}
                  bgStyle={{ fill: "#387DC0" }}
                />
              </WhatsappShareButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
